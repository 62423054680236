
//Background image in hero, source: https://codepen.io/leonidboykov/pen/Edvvpm
.hero {
  &.has-background {
    position: relative;
    overflow: hidden; }
  &-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    &.is-transparent {
      opacity: 0.3; } }
  > .tags {
    z-index: 1; }
  > .hero-body {
    z-index: 1; }
  > .hero-foot {
    z-index: 1; } }


//Text highlighting inspired after https://max.hn/thoughts/how-to-create-a-highlighter-marker-effect-in-css
// .highlight2
//   margin: 0 -0.4em
//   padding: 0.1em 0.4em
//   border-radius: 0.8em 0.3em
//   background: transparent
//   -webkit-box-decoration-break: clone
//   box-decoration-break: clone
//   display: inline-block //in order to fit highlighing to text when used in a div
//   &-color1
//     background-image: linear-gradient(to right,rgba($color1, 0.1),rgba($color1, 0.9) 4%,rgba($color1, 0.3))
//   &-color2
//     background-image: linear-gradient(to right,rgba($color2, 0.1),rgba($color2, 0.9) 4%,rgba($color2, 0.3))

// inspired after https://stackoverflow.com/a/62484130
// .highlight
//   padding: 0 13.7px
//   -webkit-box-decoration-break: clone
//   margin: 0
//   border-radius: 7.5px
//   &-color1
//     background: linear-gradient(104deg, rgba($color1,0) 0.9%, rgba($color1,1.25) 2.4%, rgba($color1,0.5) 5.8%, rgba($color1,0.1) 93%, rgba($color1,0.7) 96%, rgba($color1,0) 98%), linear-gradient(183deg, rgba($color1,0) 0%, rgba($color1,0.3) 7.9%, rgba($color1,0) 15%)
//     text-shadow: -12px 12px 9.8px rgba($color1,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1)
//   &-color2
//     background: linear-gradient(104deg, rgba($color2,0) 0.9%, rgba($color2,1.25) 2.4%, rgba($color2,0.5) 5.8%, rgba($color2,0.1) 93%, rgba($color2,0.7) 96%, rgba($color2,0) 98%), linear-gradient(183deg, rgba($color2,0) 0%, rgba($color2,0.3) 7.9%, rgba($color2,0) 15%)
//     text-shadow: -12px 12px 9.8px rgba($color2,0.7), 21px -18.1px 7.3px rgba(255, 255, 255,1), -18.1px -27.3px 30px rgba(255, 255, 255,1)

//   h1, h2, h3, h4, h5, h6
//   &.highlight, .highlight2
//     display: inline-block //in order to fit highlighing to text when used in a div


// links style hovering, inspired after https://codepen.io/shelaine/pen/zJWMNQ
// use https://cssgradient.io/ for help on linear gradient
.content a:not(.button):not(.escape-a) {
  //color:
  text-decoration: none;
  background-position-y: -0%;
  background-image: linear-gradient(transparent 0%, transparent 90%, $link 100%);
  transition: background 500ms ease;
  &:hover {
    background-image: linear-gradient(transparent 50%, $link 50%);
    background-position-y: 100%;
    background-size: 2px;
    background-size: auto 200%;
    color: $link-invert !important; } }




// Clearfix
.bulma-clearfix-mixin {
  @include clearfix; }


// Masonry
.masonry-with-columns {
  columns: 6 200px;
  column-gap: 1rem;
  div {
    display: inline-block;
    width: 100%;
    text-align: center; } }
// Masonry end

// Border Radius
.has-radius {
  border-radius: 0.8em !important; }


// Wrapper (extending or replacing .container)
.wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%; }
.wrapper {
  padding-right: 1em;
  padding-left: 1em;
  max-width: 40em;
  &.wrap-50 {
    max-width: 50em; } }

// Modal
.modal-button {
  cursor: pointer; }

// Buttons
.button {
  border-radius: 0.8rem;
  &.is-color1 {
    font-weight: $weight-medium;
    border-color: $color1-invert !important;
    &:hover,
    &.is-hovered {
      background-color: $color1-invert !important;
      color: $color1 !important; } } }
