.swiper {
  div {
    &:not(.slide-active) {
      & > .slider-background-white-overlay {
        background-color: white;
        opacity: 0.5;
        z-index: 1; } } }
  .swiper-wrapper {
    align-items: center; }
  .slide-active {
    & > .slider-background-white-overlay {
      z-index: -1;
      display: none; } }
  .swiper-button-prev, .swiper-button-next {
    padding: 1em;
    border-radius: 0.2em;
    border-style: solid;
    border-width: 0.05em;
    border-color: $color1-invert; }
  @at-root --swiper-theme-color: #{$color1}; }
