$color1: #EBC21E;
$color1-invert: black;
$color2: #5E421D;
$color2-invert: white;
$footer-background-color: black;
$navbar-background-color: black;


@import "main";

