// Bulma Default weights
// $weight-light    	font-weight 	300
// $weight-normal    	font-weight 	400
// $weight-medium   	font-weight 	500
// $weight-semibold 	font-weight 	600
// $weight-bold 	    font-weight 	700


@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-thin-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-thin-webfont.woff') format('woff');
    font-weight: 300;

}
@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-light-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-light-webfont.woff') format('woff');
    font-weight: 400;

}
@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-webfont.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-bold-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-bold-webfont.woff') format('woff');
    font-weight: 600;
}
@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-heavy-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-heavy-webfont.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: 'vision';
    src: url('fonts/Vision/webkit/vision-black-webfont.woff2') format('woff2'),
         url('fonts/Vision/webkit/vision-black-webfont.woff') format('woff');
    font-weight: 800;
}