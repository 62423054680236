.flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.outer-menu {
  position: fixed;
  top: 50vh;
  right: 65px;
  z-index: 11;

  .checkbox-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0;

    &:checked {
      + .burger-container > .hamburger > div {
        transform: rotate(135deg);

        &:before {
          top: 0;
          transform: rotate(90deg); }

        &:after {
          top: 0;
          transform: rotate(90deg);
          opacity: 0; } }

      ~ .menu-fullscreen {
        pointer-events: auto;
        visibility: visible;

        > div {
          transform: scale(1);
          transition-duration: 0.75s;

          > div {
            opacity: 1;
            transition: opacity 0.4s ease 0.4s; } } } }

    &:hover + .burger-container > .hamburger {
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1); }

    &:checked:hover + .burger-container > .hamburger > div {
      transform: rotate(225deg); } }

  .burger-container {
    position: absolute;
    z-index: 1;
    color: $menu-color; }

  .burger-container > .hamburger {
    width: 60px;
    height: 50px;
    padding: 0.5em 0.5em;
    background: $menu-color;
    border-radius: 0.12em;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: 2px;
      background: #FEFEFE;
      transition: all 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: inherit;
        transition: all 0.4s ease; }

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: inherit;
        transition: all 0.4s ease;
        top: 10px; } } }

  .menu-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 200vw;
      height: 200vw;
      color: #FEFEFE;
      background: change-color($menu-color, $alpha: 0.95);
      border-radius: 50%;
      transition: all 0.4s ease;
      flex: none;
      transform: scale(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        text-align: center;
        opacity: 0;
        transition: opacity 0.4s ease;
        overflow-y: auto;
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }

      > #menu-logo {
        max-height: 45vh;
        width: 100vw;

        > .columns {
          width: 95%; } }

      > #menu-items {
        max-height: 55vh;
        width: 90vw;
        padding-top: 2em;
        padding-bottom: 1em;

        > a {
          color: #FEFEFE;
          position: relative;
          display: inline;
          cursor: pointer;
          transition: color 0.4s ease;
          padding-top: 0.8em;

          &.is-active {
            color: $yellow; }


          &:hover {
            color: rgb(229, 229, 229);

            &:after {
              width: 100%; } }

          &:after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: -0.15em;
            left: 0;
            width: 0;
            height: 2px;
            background: #e5e5e5;
            transition: width 0.4s ease; } } } } } }
