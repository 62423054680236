@import "derived-variables";

@mixin clearfix {
  &::after {
    clear: both;
    content: " ";
    display: table; } }

@mixin center($width, $height: 0) {
  position: absolute;
  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2)); }
  @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 2)); } }

@mixin fa($size, $dimensions) {
  display: inline-block;
  font-size: $size;
  height: $dimensions;
  line-height: $dimensions;
  text-align: center;
  vertical-align: top;
  width: $dimensions; }

@mixin hamburger($dimensions) {
  cursor: pointer;
  display: block;
  height: $dimensions;
  position: relative;
  width: $dimensions;
  span {
    background-color: currentColor;
    display: block;
    height: 1px;
    left: calc(50% - 8px);
    position: absolute;
    transform-origin: center;
    transition-duration: $speed;
    transition-property: background-color, opacity, transform;
    transition-timing-function: $easing;
    width: 16px;
    &:nth-child(1) {
      top: calc(50% - 6px); }
    &:nth-child(2) {
      top: calc(50% - 1px); }
    &:nth-child(3) {
      top: calc(50% + 4px); } }
  &:hover {
    background-color: bulmaRgba(black, 0.05); }
  // Modifers
  &.is-active {
    span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg); }
      &:nth-child(2) {
        opacity: 0; }
      &:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg); } } } }

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch; }

@mixin placeholder {
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content; } } }

@mixin reset {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: 1em;
  margin: 0;
  padding: 0; }

// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content; } }

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content; } }

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content; } }

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content; } }

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content; } }

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content; } }

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content; } }

@mixin desktop-only {
  @if $widescreen-enabled {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
      @content; } } }

@mixin until-widescreen {
  @if $widescreen-enabled {
    @media screen and (max-width: $widescreen - 1px) {
      @content; } } }

@mixin widescreen {
  @if $widescreen-enabled {
    @media screen and (min-width: $widescreen) {
      @content; } } }

@mixin widescreen-only {
  @if $widescreen-enabled and $fullhd-enabled {
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
      @content; } } }

@mixin until-fullhd {
  @if $fullhd-enabled {
    @media screen and (max-width: $fullhd - 1px) {
      @content; } } }

@mixin fullhd {
  @if $fullhd-enabled {
    @media screen and (min-width: $fullhd) {
      @content; } } }

@mixin ltr {
  @if not $rtl {
    @content; } }

@mixin rtl {
  @if $rtl {
    @content; } }

@mixin ltr-property($property, $spacing, $right: true) {
  $normal: if($right, "right", "left");
  $opposite: if($right, "left", "right");
  @if $rtl {
    #{$property}-#{$opposite}: $spacing; }
  @else {
    #{$property}-#{$normal}: $spacing; } }

@mixin ltr-position($spacing, $right: true) {
  $normal: if($right, "right", "left");
  $opposite: if($right, "left", "right");
  @if $rtl {
    #{$opposite}: $spacing; }
  @else {
    #{$normal}: $spacing; } }

// Placeholders

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

@mixin arrow($color: transparent) {
  border: 3px solid $color;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em; }

@mixin block($spacing: $block-spacing) {
  &:not(:last-child) {
    margin-bottom: $spacing; } }

@mixin delete {
  @include unselectable;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: bulmaRgba($scheme-invert, 0.2);
  border: none;
  border-radius: $radius-rounded;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
  &::before,
  &::after {
    background-color: $scheme-main;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center; }
  &::before {
    height: 2px;
    width: 50%; }
  &::after {
    height: 50%;
    width: 2px; }
  &:hover,
  &:focus {
    background-color: bulmaRgba($scheme-invert, 0.3); }
  &:active {
    background-color: bulmaRgba($scheme-invert, 0.4); }
  // Sizes
  &.is-small {
    height: 16px;
    max-height: 16px;
    max-width: 16px;
    min-height: 16px;
    min-width: 16px;
    width: 16px; }
  &.is-medium {
    height: 24px;
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px; }
  &.is-large {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    min-height: 32px;
    min-width: 32px;
    width: 32px; } }

@mixin loader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $grey-lighter;
  border-radius: $radius-rounded;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em; }

@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset; }

